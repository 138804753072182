import React from 'react';

import Seo from "@/components/seo.js"

const NotFoundPage = ({ path }): JSX.Element => (
    <>
        <Seo />
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness..</p>
    </>
);

export default NotFoundPage;
